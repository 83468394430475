export const languageConstants = {
  locals: {
    it: 'it',
    en: 'en'
  } as { [key: string]: string },
  stories: {
    it: {
      vat: 'P.IVA',
      home: 'home',
      contacts: 'contatti',
      pa: 'Pubblica Amministrazione',
      welcome_title:
        "Anomaleet, grazie ad algoritmi proprietari di intelligenza artificiale, rileva l'esperienza dei veicoli su strada ed analizza il grado di ammaloramento stradale.",
      welcome_description:
        'È un sistema automatico, efficiente, rapido ed economico per monitorare autostrade, strade urbane ed extraurbane, marciapiedi e piste ciclabili.',
      book_demo: 'Prenota una sperimentazione',
      watch_video: 'Vai al video per vederci in azione',
      we_are_working_with: 'Chi ci ha scelto',
      trusted_by: 'Ci hanno scelto',
      how_it_works: 'Come funziona',
      anomaleet_3_phases: 'Anomaleet in 3 fasi',
      contact_us: 'Contattaci',
      contact_us_description:
        'Compila il form e mettiti in contatto con il nostro team',
      analyze_route: 'Analizziamo il percorso',
      analyze_route_description:
        "Sopralluogo e rilievo grazie all'AnomaleetCAR",
      monitor_suite: 'Monitora la piattaforma',
      monitor_suite_description:
        'Analizza le criticità del manto stradale attraverso il portale',
      detection_system: 'Sistema di rilevazione',
      road_surface_maintenance: 'Algoritmi di intelligenza artificiale',
      video_description:
        "Anomaleet è il sistema rivoluzionario per l'analisi e la gestione dei dissesti stradali, che unisce una piattaforma informatica avanzata con algoritmi proprietari di intelligenza artificiale.",
      video_description_2:
        'Grazie a tecnologie di rilevazione innovative, Anomaleet fa tutto questo senza bisogno di veicoli speciali, rendendo il monitoraggio delle strade più semplice, efficiente e intelligente.',
      ask_infos: 'Richiedi informazioni',
      detection_mode: 'Modalità di rilievo',
      how_detection_works: 'Come funziona il rilievo',
      how_detection_works_description:
        'Il rilievo del manto stradale viene eseguito tramite sensori GPS e accelerometri installati su veicoli di servizio o di organizzazioni (TAXI, corrieri, ecc.). Non è necessario impiegare personale specializzato; il rilievo può essere effettuato anche dopo una formazione dedicata fornita da noi.',
      how_detection_works_description_2:
        'Il rilievo può avvenire a qualsiasi ora e velocità, senza interrompere o compromettere il traffico, e senza necessità di soste.',
      how_detection_works_description_3:
        'Sono disponibili due modalità principali di rilievo:',
      how_detection_works_title_4: 'Modalità Guidata (Aggiornamento Periodico)',
      how_detection_works_description_4:
        "Il nostro team si recherà sul posto con l'AnomaleetCAR per eseguire il rilievo programmato. I dati raccolti e le criticità del manto stradale saranno accessibili in tempo reale tramite la piattaforma, con report di analisi dettagliati.",
      how_detection_works_title_5:
        'Modalità Autonoma (Aggiornamento Quotidiano)',
      how_detection_works_description_5:
        'I sensori montati sui veicoli di servizio rilevano quotidianamente lo stato delle strade cittadine in modo autonomo, durante le normali attività di routine. I dati raccolti e i livelli di criticità del manto stradale saranno consultabili direttamente sulla piattaforma Anomaleet, con accesso a report di analisi dettagliati.',
      how_detection_works_title_6: 'Modalità Online (Aggiornamento Quotidiano)',
      how_detection_works_description_6:
        "I sensori saranno spediti direttamente alla sede del cliente per l'installazione sui veicoli di servizio (come quelli della polizia locale, tecnici, cantonieri o squadre di manutenzione stradale). Anomaleet registrerà automaticamente l'esperienza dei veicoli sui tratti stradali, e i dati saranno immediatamente disponibili sulla piattaforma cloud, accessibile tramite credenziali dedicate.",
      anomalies_detected: 'Anomalie rilevate',
      detections: 'Rilevazioni',
      criticality: 'Criticità',
      awards: 'Premi, riconoscimenti e partecipazioni',
      benefits: 'Vantaggi',
      why_use_anomaleet: 'Perché usare Anomaleet',
      road_security: 'Sicurezza stradale',
      road_security_description:
        'Anomaleet rileva tratti stradali anomali e potenziali rischi per cittadini, ciclisti, motociclisti e tutti i conducenti, consentendo di ridefinire le migliori strategie per interventi preventivi e garantendo una circolazione più sicura.',
      cost_reduction: 'Riduzione dei costi',
      cost_reduction_description:
        "La metodologia di Anomaleet propone priorità di intervento semplici ed argomentate finalizzate al miglioramento della sicurezza stradale  e di conseguenza all'abbattimento fino al 90% dei costi legati ai processi interni di lavorazione e dei costi sociali andati fuori controllo.",
      rapid_intervention: 'Tempi rapidi',
      rapid_intervention_description:
        "Il rilievo richiede poco tempo per essere effettuato, una intera città di 650 km lineari viene analizzata dall'AnomaleetCAR in meno di 10gg e con la modalità autonoma è possibile monitorare quotidianamente lo stato di ammaloramento registrato dai veicoli di servizio. Le analisi sono sempre pronte e disponibili direttamente sulla piattaforma.",
      monitoring_and_prevision: 'Monitoraggio e previsione',
      monitoring_and_prevision_description:
        'La piattaforma offre viste di sintesi basate sui dati raccolti, calcola gli indici di stato e permette la simulazione di diverse strategie manutentive organizzando lotti di lavorazione e pemettendo di condividere informazioni con partner, manutentori, assicuratori e colleghi di reparti differenti della propria organizzazione.',
      about_us: 'Dicono di noi',
      case_study: 'Casi studio',
      case_study_text: 'Inserisci la tua email per scaricare i casi studio',
      case_study_link: 'Scarica i casi studio',
      suite_subtitle: 'Sengnalazione criticità',
      suite_title: 'Piattaforma di monitoraggio',
      suite_description:
        'La piattaforma Anomaleet raccoglie automaticamente i dati rilevati durante la guida del veicolo.',
      suite_description_2:
        "L'algoritmo classifica i tratti stradali in quattro livelli di criticità, valutando la densità degli ammaloramenti e la frequenza del rischio in base alle vibrazioni e alla condizione dell'asfalto. Per ogni settore stradale viene indicata con precisione la posizione geografica.",
      pa_subtitle: 'adatto alla pa',
      pa_title: 'Pensato per la Pubblica Amministrazione',
      pa_description:
        'Anomaleet si rivolge prevalentemente alla Pubblica Amministrazione e ai gestori di strade pubbliche e private. In genere questi enti si ritrovano ad agire a posteriori, risolvendo criticità emerse in aree molto estese e senza poter contare su una misurazione in modalità digitale.',
      pa_description_2:
        'Il nostro servizio ti consente di migliorare la mobilità e sicurezza cittadina permettendoti di:',
      pa_list:
        "efficientare le spese per la manutenzione;avere una completa analisi del territorio in poco tempo;analizzare il degrado stradale tramite una piattaforma digitale dedicata;prevedere l'usura del manto stradale nelle zone critiche;evitare la gestione di bandi e gare d'appalto",
      bike_subtitle: 'Adatto ad ogni percorso',
      bike_title: 'Piste ciclabili e marciapiedi con AnomaleetBIKE',
      bike_description:
        'AnomaleetBIKE è la bicicletta 100% elettrica in grado di fare rilevazioni sulle piste ciclabili e sui marciapiedi.',
      bike_description_2:
        "Abbiamo creato questo veicolo speciale grazie alla tecnologia già collaudata dell'AnomaleetCAR.",
      bike_description_3:
        "L'operatore può effettuare i percorsi e rilevare le criticità grazie ai sensori mobili e la telecamera dedicata. Le analisi verranno poi inviate alla piattaforma che collezionerà i dati in formato digitale.",
      bike_description_4:
        "L'operatore può effettuare i percorsi e rilevare le criticità grazie ai sensori mobili e la telecamera dedicata. Le analisi verranno poi inviate alla piattaforma che collezionerà i dati in formato digitale.",
      contact_title:
        'Prenota il test nella tua città o contattaci per saperne di più',
      contact_description:
        'Compila il form e ti ricontatteremo il prima possibile per rispondere alle tue domande.',
      name: 'Nome',
      surname: 'Cognome',
      email: 'Email',
      phone: 'Telefono',
      message: 'Messaggio',
      send_request: 'Invia richiesta',
      message_sent: 'Messaggio inviato, ti contatteremo appena possibile',
      message_error: "Errore nell'invio del messaggio, riprova più tardi",
      more: 'Scopri di più',
      partners: 'Partners',
      accept_privacy_policy: 'Inviado la richiesta accetti la nostra ',
      company: 'Azienda',
      social: 'Social',
      privacy: 'Informativa sulla Privacy',
      get_in_touch: 'Contattaci',
      required: 'obbligatorio',
      book_test: 'Prenota una Sperimentazione nella tua città',
      book_test_subtitle: 'Sperimentazione',
      book_test_contact: 'Metitti in contatto',
      book_test_contact_description:
        'Compila il form e parla con il nostro team.',
      book_test_analyze: 'Analisi di 30 km lineari',
      book_test_analyze_description:
        "Lascia che l'AnomaleetCAR rilevi le condizioni del manto stradale.",
      book_test_monitor: 'Monitora la piattaforma',
      book_test_monitor_description:
        "Accedi ai report e al portale per analizzare l'indice di ammaloramento e la frequenza di rischio delle tue strade.",
      subject: 'Oggetto',
      general_contact: 'Contatto generale',
      free_trial: 'Sperimentazione nella mia città',
      mode: 'modalità',
      autonomous: 'autonoma',
      guided: 'guidata',
      online: 'online',
      license: 'Licenza',
      device: 'Dispositivo',
      start: 'avviamento',
      setup: 'installazione',
      access_credentials: 'Credenziali di accesso',
      remote_assistance: 'Assistenza remota',
      accelerometer_measurement: 'rilevazioni accelerometriche',
      photo_measurement: 'rilevazioni fotografiche',
      report: 'report analisi',
      photo_analysis: 'analisi foto',
      lots: 'lotti',
      minimum_contract: 'periodo minimo contrattuale',
      automatic_renewal: 'rinnovo automatico',
      filter: 'filtro competenza',
      training: 'formazione',
      consultancy: 'consulenza',
      filter_integration: 'integrazione filtro competenza',
      transfer: 'trasferta',
      quoted_separately: 'quotato a parte',
      plans: 'Modalità di rilevazione',
      subscriptions: 'Abbonamenti e piani'
    },
    en: {
      vat: 'VAT',
      home: 'home',
      contacts: 'contacts',
      pa: 'Public Administration',
      welcome_title:
        'Anomaleet, with proprietary artificial intelligence algorithms, detects the experience of vehicles on the road and analyzes the degree of road deterioration.',
      welcome_description:
        'It is an automatic, efficient, fast and cost-effective system for monitoring motorways, urban and suburban roads, pavements and cycle paths.',
      book_demo: 'Book a trial',
      watch_video: 'Watch the video to see us in action',
      we_are_working_with: 'who chose us',
      how_it_works: 'How it works',
      anomaleet_3_phases: 'Anomaleet in 3 phases',
      contact_us: 'Contact us',
      contact_us_description:
        'Fill out the form and get in touch with our team',
      analyze_route: 'Analyze the route',
      analyze_route_description: 'Inspection and survey with the AnomaleetCAR',
      monitor_suite: 'Monitor the platform',
      monitor_suite_description: 'Analyze the road surface through the portal',
      detection_system: 'Detection system',
      road_surface_maintenance: 'Artificial intelligence algorithms',
      trusted_by: 'Trusted by',
      video_description:
        'Anomaleet is the revolutionary system for the analysis and management of road disruptions, combining an advanced computing platform with proprietary artificial intelligence algorithms.',
      video_description_2:
        'With innovative detection technologies, Anomaleet does all this without the need for special vehicles, making road monitoring easier, more efficient and smarter.',
      ask_infos: 'Ask for information',
      detection_mode: 'Detection mode',
      how_detection_works: 'How detection works',
      how_detection_works_description:
        'The road surface survey is performed using GPS sensors and accelerometers installed on service vehicles or those of organisations (TAXIs, couriers, etc.). It is not necessary to employ specialised personnel; the survey can also be carried out after dedicated training provided by us.',
      how_detection_works_description_2:
        'The survey can take place at any time and speed, without interrupting or affecting traffic, and without the need for stops.',
      how_detection_works_description_3:
        'Two main modes of detection are available:',
      how_detection_works_title_4: 'Guided Mode (Periodic Update)',
      how_detection_works_description_4:
        'Our team will go to the site with the AnomaleetCAR to perform the scheduled survey. The collected data and the road surface criticalities will be accessible in real time through the platform, with detailed analysis reports.',
      how_detection_works_title_5: 'Autonomous Mode (Daily Update)',
      how_detection_works_description_5:
        'The sensors mounted on service vehicles detect the state of city roads daily in an autonomous way, during normal routine activities. The collected data and the levels of road surface criticality will be directly accessible on the Anomaleet platform, with access to detailed analysis reports.',
      how_detection_works_title_6: 'Online Mode (Daily Update)',
      how_detection_works_description_6:
        "The sensors will be shipped directly to the customer's premises for installation on service vehicles (such as those of the local police, technicians, road maintenance teams or road maintenance teams). Anomaleet will automatically record the experience of vehicles on the road sections, and the data will be immediately available on the cloud platform, accessible via dedicated credentials.",
      anomalies_detected: 'Anomalies detected',
      detections: 'Detections',
      criticality: 'Critical issues',
      awards: 'Awards and recognitions',
      benefits: 'Benefits',
      why_use_anomaleet: 'Why use Anomaleet?',
      road_security: 'Road security',
      road_security_description:
        'Anomaleet detects anomalous road sections and potential risks for citizens, cyclists, motorcyclists and all drivers, allowing you to redefine the best strategies for preventive interventions and ensuring safer traffic.',
      cost_reduction: 'Cost reduction',
      cost_reduction_description:
        "Anomaleet's methodology proposes simple and reasoned intervention priorities aimed at improving road safety and consequently reducing up to 90% of the costs related to internal processing processes and social costs that have gone out of control.",
      rapid_intervention: 'Rapid intervention',
      rapid_intervention_description:
        'The survey takes little time to be carried out, an entire city of 650 km of linear roads is analysed by AnomaleetCAR in less than 10 days and with the autonomous mode it is possible to monitor daily the degree of deterioration recorded by service vehicles. The analyses are always ready and available directly on the platform.',
      monitoring_and_prevision: 'Monitoring and prevision',
      monitoring_and_prevision_description:
        'The platform offers summary views based on the collected data, calculates the state indices and allows the simulation of different maintenance strategies by organising processing lots and allowing you to share information with partners, maintainers, insurers and colleagues from different departments of your organisation.',
      about_us: 'About us',
      case_study: 'Case studies',
      case_study_text: 'Enter your email to download the case studies',
      case_study_link: 'Download case studies',
      suite_subtitle: 'Report critical issues',
      suite_title: 'Monitoring platform',
      pa_subtitle: 'Suitable for PA',
      suite_description:
        'The Anomaleet platform automatically collects the data collected while driving the vehicle.',
      suite_description_2:
        'The algorithm classifies road sections into four levels of criticality, assessing the density of damage and the frequency of risk based on vibration and asphalt condition. For each road section, the precise geographical location is indicated.',
      pa_title: 'Designed for Public Administration',
      pa_description:
        'Anomaleet is mainly aimed at Public Administration and managers of public and private roads. These entities generally find themselves acting after the fact, resolving critical issues that have emerged in very large areas and without being able to rely on digital measurement.',
      pa_description_2:
        'Our service enables you to improve city mobility and safety by allowing you to:',
      pa_list:
        'streamline maintenance expenditures;have a complete analysis of the territory in a short time;analyze road deterioration through a dedicated digital platform;predict road surface wear and tear in critical areas;avoid managing calls and tenders',
      bike_subtitle: 'Suitable for every route',
      bike_title: 'Cycle paths and sidewalks with AnomaleetBIKE',
      bike_description:
        'AnomaleetBIKE is the 100% electric bicycle that can make detections on bike lanes and sidewalks.',
      bike_description_2:
        'We created this special vehicle through the already proven technology of the AnomaleetCAR.',
      bike_description_3:
        'The operator can run routes and detect critical issues using mobile sensors and the dedicated camera. The analyses will then be sent to the platform, which will collect the data in digital format.',
      bike_description_4:
        'The operator can run routes and detect critical issues using mobile sensors and the dedicated camera. The analyses will then be sent to the platform, which will collect the data in digital format.',
      contact_title: 'Book the test in your city or contact us to learn more',
      contact_description:
        'Fill out the form and we will get back to you as soon as possible to answer your questions.',
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      send_request: 'Send request',
      message_sent: 'Message sent, we will contact you as soon as possible',
      message_error: 'Error sending message, please try again later',
      more: 'Learn more',
      partners: 'Partners',
      accept_privacy_policy: 'By sending the request you accept our ',
      company: 'Company',
      social: 'Social',
      privacy: 'Privacy Policy',
      get_in_touch: 'Get in touch',
      required: 'required',
      book_test: 'Book a Test in your city',
      book_test_subtitle: 'Trial',
      book_test_contact: 'Get in touch',
      book_test_contact_description: 'Fill out the form and talk to our team.',
      book_test_analyze: 'Analysis of 30 km of linear roads',
      book_test_analyze_description:
        'Let the AnomaleetCAR detect the road surface conditions.',
      book_test_monitor: 'Monitor the platform',
      book_test_monitor_description:
        'Access reports and the portal to analyze the road deterioration index and the risk frequency of your roads.',
      subject: 'Subject',
      general_contact: 'General contact',
      free_trial: 'Trial in my city',
      mode: 'mode',
      autonomous: 'autonomous',
      guided: 'guided',
      online: 'online',
      license: 'License',
      device: 'Device',
      start: 'start',
      setup: 'setup',
      access_credentials: 'Access credentials',
      remote_assistance: 'Remote assistance',
      accelerometer_measurement: 'accelerometer measurements',
      photo_measurement: 'photo measurements',
      report: 'analysis report',
      photo_analysis: 'photo analysis',
      lots: 'lots',
      minimum_contract: 'minimum contract period',
      automatic_renewal: 'automatic renewal',
      filter: 'competence filter',
      training: 'training',
      consultancy: 'consultancy',
      filter_integration: 'competence filter integration',
      transfer: 'transfer',
      quoted_separately: 'quoted separately',
      plans: 'Detection mode',
      subscriptions: 'Subscriptions and plans'
    }
  } as { [key: string]: { [key: string]: string } }
}
