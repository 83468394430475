export const uiConstants = {
  contacts: [
    {
      icon: 'fa-solid fa-phone',
      to: 'tel:+393334400878',
      label: '+39 333 440 0878',
      important: true
    },
    {
      icon: 'fa-brands fa-whatsapp',
      to: 'https://wa.me/+393334400878',
      label: '+39 333 440 0878'
    },
    {
      icon: 'fa-regular fa-envelope',
      to: 'mailto:info@anomaleet.com',
      label: 'info@anomaleet.com',
      important: true
    },
    {
      icon: 'fa-solid fa-at',
      to: 'mailto:anomaleet@pec.it',
      label: 'anomaleet@pec.it',
      important: true
    },
    {
      icon: 'fa-solid fa-map-pin',
      to: 'https://goo.gl/maps/C51RnDsduptwP8qg8',
      label: '8FQF J9XH+9F'
    }
  ],
  social: [
    {
      icon: 'fa-brands fa-facebook-f',
      to: 'https://www.facebook.com/anomaleet',
      label: 'facebook'
    },
    {
      icon: 'fa-brands fa-twitter',
      to: 'https://twitter.com/anomaleetsrl',
      label: 'twitter'
    },
    {
      icon: 'fa-brands fa-instagram',
      to: 'https://www.instagram.com/anomaleet',
      label: 'instagram'
    },
    {
      icon: 'fa-brands fa-tiktok',
      to: 'https://www.tiktok.com/@anomaleet',
      label: 'tiktok'
    },
    {
      icon: 'fa-brands fa-youtube',
      to: 'https://www.youtube.com/channel/UC1e3F2hu4VGHKnfrlil30kw',
      label: 'youtube'
    },
    {
      icon: 'fa-brands fa-linkedin',
      to: 'https://www.linkedin.com/company/anomaleet/',
      label: 'linkedin'
    }
  ],
  localStorage: {
    language: 'language'
  },
  menu: [
    {
      label: 'home',
      to: '/'
    },
    {
      label: 'how_it_works',
      to: '/how-it-works'
    },
    {
      label: 'benefits',
      to: '/benefits'
    },
    {
      label: 'pa',
      to: '/public-administration'
    },
    {
      label: 'get_in_touch',
      to: '/contact'
    }
  ],
  customers: [
    {
      image: 'comune_abbiategrasso.png',
      label: 'Comune di Abbiategrasso'
    },
    {
      image: 'comune_guidonia.png',
      label: 'Comune di Guidonia Montecelio'
    },
    {
      image: 'comune_lecco.png',
      label: 'Comune di Lecco'
    },
    {
      image: 'comune_padova.png',
      label: 'Comune di Padova'
    },
    {
      image: 'comune_pavia.png',
      label: 'Comune di Pavia'
    },
    {
      image: 'comune_roma.png',
      label: 'Comune di Roma'
    },
    {
      image: 'comune_vicenza.png',
      label: 'Comune di Vicenza'
    },
    {
      image: 'metropolitana_milano.png',
      label: 'Città Metropolitana di Milano'
    },
    {
      image: 'metropolitana_torino.png',
      label: 'Città Metropolitana di Torino'
    },
    {
      image: 'provincia_cuneo.png',
      label: 'Provincia di Cuneo'
    },
    {
      image: 'provincia_novara.png',
      label: 'Provincia di Novara'
    },
    {
      image: 'provincia_bergamo.png',
      label: 'Provincia di Bergamo'
    },
    {
      image: 'comune_vigevano.png',
      label: 'Comune di Vigevano'
    }
  ],
  awards: [
    {
      image: 'abudhabi.png',
      label: 'Abu Dhabi Road World Congress'
    },
    {
      image: 'austrian.png',
      label: 'Austrian Business Circle'
    },
    {
      image: 'cambiamenti.png',
      label: 'Cambiamenti'
    },
    {
      image: 'mce.png',
      label: 'MCE4x4'
    },
    {
      image: 'rideon.png',
      label: 'Ride On'
    },
    {
      image: 'tavolo_giovani.png',
      label: 'Tavolo Giovani Milano'
    },
    {
      image: 'fondazione_italia_usa.png',
      label: 'Premio America Innovazione'
    }
  ],
  aboutUs: [
    {
      image: 'primalecco.png',
      text: "Vince il premio per l'innovazione per la vita, per la capacità di innovare a tutela della vita dei motociclisti.",
      footer: 'Nuovo riconoscimento per Anomaleet - Prima Lecco'
    },
    {
      image: 'cittadinolodi.png',
      text: 'Una casa cantoniera all’avanguardia, dove la tecnologia è indispensabile',
      footer: 'Il Cittadino di Lodi'
    },
    {
      image: 'ilmessaggero.png',
      text: 'Strade sicure in nome di Elena. App anti-buca sui mezzi pubblici',
      footer: 'Il Messaggero'
    },
    {
      image: 'provincialecco.png',
      text: 'Manutenzione delle strade, è un algoritmo a dare la priorità.',
      footer: 'La Provincia di Lecco'
    }
  ],
  partners: [
    // {
    //   image: 'mag.svg',
    //   label: 'MAG'
    // },
    {
      image: 'marsh.png',
      label: 'Marsh'
    },
    {
      image: 'enercom.png',
      label: 'Gruppo Enercom'
    },
    {
      image: 'gepit.jpg',
      label: 'Gepit'
    },
    {
      image: 'zeromille.jpg',
      label: 'Moto Club da Zero a Mille ASD'
    },
    {
      image: 'legalriders.jpg',
      label: 'Legal Riders'
    },
    {
      image: '2nove9.png',
      label: '2NOVE9 Associazione Vittime incidenti stradali - APS'
    },
    {
      image: 'polo_eccellenze.png',
      label: 'Polo delle Eccellenze'
    },
    {
      image: 'nextown.jpeg',
      label: 'NexTown'
    },
    {
      image: 'geolander.png',
      label: 'Geolander'
    },
    {
      image: 'homedefense.svg',
      label: 'Home Defense'
    }
  ],
  emailjs: {
    serviceId: 'service_t85k6ah',
    templateId: 'template_44i8syi',
    publicKey: 'H9SCORnqy0h2PbYpo'
  },
  services: [
    {
      label: 'license',
      values: [1, 0, 1]
    },
    {
      label: 'device',
      values: [1, 0, 1]
    },
    {
      label: 'start',
      values: [1, 0, 0]
    },
    {
      label: 'setup',
      values: [1, 0, 0]
    },
    {
      label: 'access_credentials',
      values: [1, 0, 0]
    },
    {
      label: 'remote_assistance',
      values: [1, 0, 1]
    },
    {
      label: 'accelerometer_measurement',
      values: [1, 1, 1]
    },
    {
      label: 'photo_measurement',
      values: [0, 1, 0]
    },
    {
      label: 'report',
      values: [0, 1, 0]
    },
    {
      label: 'photo_analysis',
      values: [0, 1, 0]
    },
    {
      label: 'lots',
      values: [1, 0, 1]
    },
    {
      label: 'minimum_contract',
      values: [1, 0, 1]
    },
    {
      label: 'automatic_renewal',
      values: [1, 0, 1]
    },
    {
      label: 'filter',
      values: [2, 2, 2]
    },
    {
      label: 'training',
      values: [2, 2, 2]
    },
    {
      label: 'consultancy',
      values: [2, 2, 2]
    },
    {
      label: 'filter_integration',
      values: [2, 2, 2]
    },
    {
      label: 'transfer',
      values: [2, 2, 2]
    }
  ]
}
