import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import PageLoader from './components/UI/PageLoader/PageLoader'
import LangProvider from './components/Context/LangContext'

const LayoutLazy = lazy(() => import('./components/Containers/Layout/Layout'))

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const PrivacyLazy = lazy(() => import('./components/Pages/Privacy/Privacy'))
const BenefitsLazy = lazy(() => import('./components/Pages/Benefits/Benefits'))
const ContactLazy = lazy(() => import('./components/Pages/Contact/Contact'))
const HowItWorksLazy = lazy(
  () => import('./components/Pages/HowItWorks/HowItWorks')
)
const PublicAdministrationLazy = lazy(
  () => import('./components/Pages/PublicAdministration/PublicAdministration')
)

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <LangProvider>
          <Routes>
            <Route element={<LayoutLazy />}>
              <Route path='/' element={<HomeLazy />} />
              <Route path='/benefits' element={<BenefitsLazy />} />
              <Route path='/contact' element={<ContactLazy />} />
              <Route path='/how-it-works' element={<HowItWorksLazy />} />
              <Route
                path='/public-administration'
                element={<PublicAdministrationLazy />}
              />
              <Route path='/privacy' element={<PrivacyLazy />} />
              <Route path='*' element={<HomeLazy />} />
            </Route>
          </Routes>
        </LangProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
