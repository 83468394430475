import React from 'react'

import css from './PageLoader.module.scss'
import Logo from '../../../assets/logo_full.svg'

const PageLoader = () => (
  <div className={css.LoaderBkg}>
    <img src={Logo} className={css.Logo} alt='anomaleet' />
    <div className={css.Loader}>
      <div className={css.LoaderBar}></div>
    </div>
  </div>
)

export default PageLoader
