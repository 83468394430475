import React, { createContext } from 'react'
import { languageConstants, uiConstants } from '../../constants'
import useLocalStorage from 'use-local-storage'

export const LangContext = createContext<{
  switchLanguage: (l: string) => void
  language: string
  stories: { [key: string]: string }
} | null>(null)

const LangProvider = ({ children }: any) => {
  const userLang = navigator.language.split('-')[0]

  const [language, setLanguage] = useLocalStorage(
    uiConstants.localStorage.language,
    languageConstants.locals[userLang] ? userLang : 'en'
  )

  const switchLanguage = (l: string) => {
    setLanguage(l)
  }

  return (
    <LangContext.Provider
      value={{
        switchLanguage,
        language,
        stories: languageConstants.stories[language]
      }}
    >
      {children}
    </LangContext.Provider>
  )
}

export default LangProvider
